// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
// import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
// eslint-disable-next-line import/no-extraneous-dependencies
import allLocales from '@fullcalendar/core/locales-all'

import { getUserData } from '@/auth/utils'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted, getCurrentInstance } from 'vue'
import store from '@/store'
import moment from 'moment'

let locale = localStorage.getItem('locale') || 'en'
if (!['vn', 'jp'].includes(locale)) {
  locale = 'en'
}
if (locale === 'vn') {
  locale = 'vi'
} else if (locale === 'jp') {
  locale = 'ja'
}
const canEditCalendar = getUserData().usertype !== 'student'

export default function userCalendar() {
  // Use toast
  const root = getCurrentInstance().proxy.$root
  const toast = root.$toast
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Business: 'primary',
    Holiday: 'success',
    Personal: 'danger',
    Family: 'warning',
    ETC: 'info',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
      gradeId: null,
      lessonName: null,
      lessonId: [],
      courseId: null,
      lessonGroupId: null,
      hasTest: root.$route.query.filter === 'only-test',
      nonSequence: false,
      schedule_type: 'sequenced',
      assignment_type: 'public',
      event_tags: [],
      order_index: 1,
      students: [],
      skill_type: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  const getEvent = (template = null) => {
    if (template) {
      event.value.extendedProps.assignment_type = 'isolated'
      event.value.extendedProps.template_id = template.id
    }
    return event.value
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  const addEventInCalendar = () => {
    toast({
      component: ToastificationContent,
      position: 'bottom-right',
      props: {
        title: 'Event Added',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
  }
  const updateEventInCalendar = () => {
    toast({
      component: ToastificationContent,
      position: 'bottom-right',
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
  }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { lessonName, courseId, gradeId, gradeName, lessonGroupId, courseName, lessonGroupName, lessonPlans, lessonId, calendar, guests, location, description, testInfo, hasTest, nonSequence, event_type, event_title, event_link, event_description, hasMinimalView, schedule_type, assignment_type, order_index, students, has_multi_practice, skill_type, event_tags },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        lessonName,
        lessonId,
        guests,
        location,
        description,
        courseId,
        gradeId,
        gradeName,
        lessonGroupId,
        courseName,
        lessonPlans,
        lessonGroupName,
        testInfo,
        hasTest,
        event_type,
        event_title,
        event_link,
        event_description,
        assignment_type,
        order_index,
        students,
        nonSequence,
        hasMinimalView,
        schedule_type,
        has_multi_practice,
        skill_type,
        event_tags,
      },
      event_title,
      event_link,
      event_description,
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = (eventData, onComplete) => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
      addEventInCalendar()
      if (typeof onComplete === 'function') {
        onComplete(true)
      }
    }).catch(error => {
      const errors = Object.values(error.response?.data.errors).map(err => err)
      if (typeof onComplete === 'function') {
        onComplete(false)
      }
      toast({
        component: ToastificationContent,
        props: {
          position: 'bottom-left',
          title: errors.join('\n'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = (eventData, onComplete) => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
      updateEventInCalendar()
      if (typeof onComplete === 'function') {
        onComplete(true)
      }
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })
  const allEventsList = ref([])
  const availableFilters = ref([])
  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
      })
      .then(response => {
        const events = response.data.map(d => ({ ...d, allDay: true }))
        allEventsList.value = events
        if (availableFilters.value.length === 0) {
          const available = []
          allEventsList.value.forEach(item => {
            available.push(item.extendedProps.event_type)
            available.push(item.extendedProps.schedule_type)
          })
          availableFilters.value = [...new Set(available)]
        }
        successCallback(events)
      })
      .catch(error => {
        console.error(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    locales: allLocales,
    locale,
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    eventOrder: 'order_index',
    events: fetchEvents,
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: canEditCalendar,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 5,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      let colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]
      if (!colorName) colorName = 'info'
      let eventColor = ''
      if (calendarEvent.extendedProps.schedule_type === 'sequenced') eventColor = 'sequenced'
      if (calendarEvent.extendedProps.schedule_type === 'non-sequenced') eventColor = 'non-sequenced'
      if (calendarEvent.extendedProps.schedule_type === 'unlockable-sequenced') eventColor = 'unlockable-sequenced'
      if (calendarEvent.extendedProps.event_type?.startsWith('class_test')) eventColor = 'class-test'
      if (calendarEvent.extendedProps.event_type === 'instruction') eventColor = 'instruction'
      if (calendarEvent.extendedProps.event_type === 'follow_up') eventColor = 'follow-up'
      if (calendarEvent.extendedProps.event_type === 'conversational') eventColor = 'conversational'
      return [
        // Background Color
        // `bg-light-${colorName}`,
        `event-${eventColor}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      const eventInfo = grabEventDataFromEventApi(clickedEvent)
      const anyTimeAccessibleEvents = ['non-sequenced', 'unlockable-sequenced']
      if (!canEditCalendar) {
        if (clickedEvent.extendedProps.event_type === 'instruction') {
          // eslint-disable-next-line no-use-before-define
          instructionEventToShow.value = clickedEvent.extendedProps
          console.log('not student')
        } else if (anyTimeAccessibleEvents.includes(clickedEvent.extendedProps.schedule_type) || moment(clickedEvent.start).isSameOrBefore(moment(), 'day')) {
          if (getUserData().usertype !== 'student') {
            window.open(`/student/class/${root.$route.params.classId}/lesson/${clickedEvent.extendedProps.lessonId}?event_id=${eventInfo.id}`)
          } else if (clickedEvent.extendedProps.event_type === 'vocab_words') {
            window.location.href = `/student/vocab-lab/${root.$route.params.classId}/class/${eventInfo.id}/event`
          } else {
            window.location.href = `/student/class/${root.$route.params.classId}/lesson/${clickedEvent.extendedProps.lessonId}?event_id=${eventInfo.id}`
          }
        } else {
          toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'The event has not yet begun.',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        return
      }
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = eventInfo
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    // eslint-disable-next-line consistent-return
    dateClick(info) {
      if (!canEditCalendar) {
        return false
      }
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */

      event.value = {
        ...event.value,
        start: info.date,
      }
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      const eventData = droppedEvent
      const toUpdate = {
        schedule_type: eventData.extendedProps.schedule_type,
        class_id: root.$route.params.classId,
        grade_id: eventData.extendedProps.gradeId,
        course_id: eventData.extendedProps.courseId,
        end_time: moment(eventData.endStr).subtract('1', 'day').format('YYYY-MM-DD'),
        hasTest: eventData.extendedProps.hasTest,
        id: eventData.id,
        event_type: eventData.event_type,
        lesson_group_id: eventData.extendedProps.lessonGroupId,
        lesson_id: eventData.extendedProps.lessonId,
        nonSequence: eventData.extendedProps.nonSequence,
        tags: eventData.extendedProps.tags || [],
        selectedScheduleType: eventData.extendedProps.selectedScheduleType,
        start_time: eventData.startStr,
        update_type: 'drag_drop',
        testInfo: eventData.extendedProps.testInfo,
        skill_type: eventData.extendedProps.skill_type,
      }
      updateEvent(toUpdate)
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)
  const instructionEventToShow = ref(null)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    getEvent,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    availableFilters,
    allEventsList,
    instructionEventToShow,
    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
